import React from "react";
import "../styles/loading.css";

const Loading = ({ message = "Loading..." }) => {
  return (
    <div id="cc-main-loading-container">
      <div id="cc-load-container">
        {Array.from(Array(10)).map((_, i) => (
          <div className="cc-loading-star" key={i} />
        ))}
        <div id="cc-loading-text-container">
          <h1 id="cc-loading-text">{message}</h1>
          <p id="cc-loading-info">
            If loading persists for longer than 30 seconds{" "}
            <a href="/">refresh the page</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Loading;
