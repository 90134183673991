import React from "react";
import "../styles/header.css";

const Header = ({ aboutRef, projectsRef, portfolioRef, contactsRef }) => {
  const scrollTo = (ref) => {
    if (ref && ref.current) ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const mobileMenuClick = () => {
    let burger = document.getElementById("burger-menu"),
      nav = document.getElementById("main-nav"),
      device = document.getElementById("device");
    device.classList.toggle("is-open");
    burger.classList.toggle("is-open");
    nav.classList.toggle("is-open");
  };

  return (
    <div id="cc-header-container">
      <h2 id="cc-name-text">Carter J. Cobb</h2>
      <div id="cc-navbar">
        <h4 onClick={() => scrollTo(aboutRef)}>About</h4>
        <div className="cc-pipe" />
        <h4 onClick={() => scrollTo(projectsRef)}>Projects</h4>
        <div className="cc-pipe" />
        <h4 onClick={() => scrollTo(portfolioRef)}>Portfolio</h4>
        <div className="cc-pipe" />
        <h4 onClick={() => scrollTo(contactsRef)}>Contacts</h4>
      </div>
      <div id="cc-mobile-nav">
        <button
          id="burger-menu"
          className="open-mobile-nav-bar"
          onClick={mobileMenuClick}
        >
          <span className="burger-menu"></span>
          <span className="burger-menu-text">Menu</span>
        </button>
      </div>
      <div className="device" id="device">
        <div className="device-container">
          <nav className="mobile-nav-bar" id="main-nav">
            <ul>
              <li>
                <button
                  onClick={() => {
                    mobileMenuClick();
                    scrollTo(aboutRef);
                  }}
                >
                  About
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    mobileMenuClick();
                    scrollTo(projectsRef);
                  }}
                >
                  Projects
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    mobileMenuClick();
                    scrollTo(portfolioRef);
                  }}
                >
                  Portfolio
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    mobileMenuClick();
                    scrollTo(contactsRef);
                  }}
                >
                  Contacts
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Header;
