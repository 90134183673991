import React, { Component } from "react";
import "../styles/particles.css";

class Particle {
  constructor(opts) {
    this.opts = opts;
    this.x = Math.random() * opts.w;
    this.y = Math.random() * opts.h;
    this.speed = opts.defaultSpeed + Math.random() * opts.variantSpeed;
    this.directionAngle = Math.floor(Math.random() * 360);
    this.color = opts.particleColor;
    this.radius = opts.defaultRadius + Math.random() * opts.variantRadius;
    this.vector = {
      x: Math.cos(this.directionAngle) * this.speed,
      y: Math.sin(this.directionAngle) * this.speed,
    };
  }

  /**
   * Update the particle position
   */
  update() {
    this.border();
    this.x += this.vector.x;
    this.y += this.vector.y;
  }

  /**
   * Particle boarder
   */
  border() {
    if (this.x >= this.opts.w || this.x <= 0) this.vector.x *= -1;
    if (this.y >= this.opts.h || this.y <= 0) this.vector.y *= -1;
    if (this.x > this.opts.w) this.x = this.opts.w;
    if (this.y > this.opts.h) this.y = this.opts.h;
    if (this.x < 0) this.x = 0;
    if (this.y < 0) this.y = 0;
  }

  /**
   * Draw the particle
   * @param {CanvasRenderingContext2D} drawArea
   */
  draw(drawArea) {
    drawArea.beginPath();
    drawArea.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
    drawArea.closePath();
    drawArea.fillStyle = this.color;
    drawArea.fill();
  }
}

export default class AnimatedDots extends Component {
  opts = {
    particleColor: "rgb(200,200,200)",
    lineColor: "rgb(200,200,200)",
    particleAmount: 10,
    defaultSpeed: 1,
    variantSpeed: 1,
    defaultRadius: 2,
    variantRadius: 2,
    linkRadius: 200,
    w: 300,
    h: 300,
  };
  particles = [];
  delay = 200;
  tid;

  constructor(props) {
    super(props);
    this.opts = { ...this.opts, ...props };
    this.loop = this.loop.bind(this);
    this.linkPoints = this.linkPoints.bind(this);
  }

  linkPoints(point1, hubs, drawArea) {
    let checkDistance = (x1, y1, x2, y2) =>
      Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    let rgb = this.opts.lineColor.match(/\d+/g);
    for (let i = 0; i < hubs.length; i++) {
      let distance = checkDistance(point1.x, point1.y, hubs[i].x, hubs[i].y);
      let opacity = 1 - distance / this.opts.linkRadius;
      if (opacity > 0) {
        drawArea.lineWidth = 0.5;
        drawArea.strokeStyle = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;
        drawArea.beginPath();
        drawArea.moveTo(point1.x, point1.y);
        drawArea.lineTo(hubs[i].x, hubs[i].y);
        drawArea.closePath();
        drawArea.stroke();
      }
    }
  }

  loop() {
    const drawArea = document
      .getElementById("particle-canvas")
      .getContext("2d");
    window.requestAnimationFrame(this.loop);
    drawArea.clearRect(0, 0, this.opts.w, this.opts.h);
    for (let i = 0; i < this.particles.length; i++) {
      this.particles[i].update();
      this.particles[i].draw(drawArea);
    }
    for (let i = 0; i < this.particles.length; i++)
      this.linkPoints(this.particles[i], this.particles, drawArea);
  }

  componentDidMount() {
    for (let i = 0; i < this.opts.particleAmount; i++)
      setTimeout(() => this.particles.push(new Particle(this.opts)), 50 * i);
    window.requestAnimationFrame(this.loop);
  }

  render() {
    return (
      <canvas id="particle-canvas" width={this.opts.w} height={this.opts.h} />
    );
  }
}
