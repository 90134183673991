import React from "react";
import {
  SiJavascript,
  SiTypescript,
  SiPython,
  SiCsharp,
  SiSolidity,
  SiCss3,
  SiHtml5,
  SiXaml,
  SiJson,
  SiSwift,
  SiTerraform,
  SiReact,
  SiMongodb,
  SiAffinitydesigner,
  SiAffinityphoto,
  SiAmazonaws,
  SiAntdesign,
  SiDocker,
  SiExpo,
  SiExpress,
  SiFirebase,
  SiGit,
  SiGithub,
  SiGooglecloud,
  SiIpfs,
  SiJsonwebtokens,
  SiMarkdown,
  SiMicrosoftazure,
  SiNginx,
  SiNodedotjs,
  SiNodemon,
  SiNpm,
  SiPaypal,
  SiPostman,
  SiPrettier,
  SiPug,
  SiRabbitmq,
  SiReactrouter,
  SiRedis,
  SiRedux,
  SiStripe,
  SiWordpress,
  SiZoho,
} from "react-icons/si";
import { GrStatusUnknown } from "react-icons/gr";

export const languages = [
  "javascript",
  "typescript",
  "css",
  "html",
  "c#",
  "python",
  "xaml",
  "json",
  "solidity",
  "swift",
  "terraform",
  // "java",
  "jsx",
  "mongo",
  "affinity-designer",
  "affinity-photo",
  "aws",
  "ant-design",
  "docker",
  "expo",
  "express",
  "firebase",
  "git",
  "github",
  "google-cloud",
  "ipfs",
  "jwt",
  "markdown",
  "azure",
  "nginx",
  "nodejs",
  "nodemon",
  "npm",
  "paypal",
  "postman",
  "prettier",
  "pug",
  "rabbitmq",
  "react-router",
  "redis",
  "redux",
  "stripe",
  "wordpress",
  "zoho",
];

const LanguageIcon = ({ language, ...props }) => {
  switch (language.toLowerCase()) {
    case "javascript":
      return (
        <SiJavascript
          color="#F7DF1E"
          className="cc-language-icon"
          id="cc-js"
          {...props}
        />
      );
    case "typescript":
      return (
        <SiTypescript
          color="#007acc"
          className="cc-language-icon"
          id="cc-ts"
          {...props}
        />
      );
    // case "java":
    //   return <SiJava color="#007396" className="cc-language-icon" {...props} />;
    case "python":
      return (
        <SiPython color="#3776AB" className="cc-language-icon" {...props} />
      );
    case "c#":
      return (
        <SiCsharp
          color="#239120"
          className="cc-language-icon"
          id="cc-csharp"
          {...props}
        />
      );
    case "solidity":
      return (
        <SiSolidity color="#363636" className="cc-language-icon" {...props} />
      );
    case "css":
      return <SiCss3 color="#1572B6" className="cc-language-icon" {...props} />;
    case "html":
      return (
        <SiHtml5 color="#E34F26" className="cc-language-icon" {...props} />
      );
    case "xaml":
      return <SiXaml color="#0C54C2" className="cc-language-icon" {...props} />;
    case "json":
      return <SiJson color="#000000" className="cc-language-icon" {...props} />;
    case "swift":
      return (
        <SiSwift color="#F05138" className="cc-language-icon" {...props} />
      );
    case "terraform":
      return (
        <SiTerraform color="#7B42BC" className="cc-language-icon" {...props} />
      );
    case "jsx":
      return (
        <SiReact color="#61DAFB" className="cc-language-icon" {...props} />
      );
    case "mongo":
      return (
        <SiMongodb color="#47A248" className="cc-language-icon" {...props} />
      );
    case "affinity-designer":
      return (
        <SiAffinitydesigner
          color="#1B72BE"
          className="cc-language-icon"
          {...props}
        />
      );
    case "affinity-photo":
      return (
        <SiAffinityphoto
          color="#7E4DD2"
          className="cc-language-icon"
          {...props}
        />
      );
    case "aws":
      return (
        <SiAmazonaws color="#232F3E" className="cc-language-icon" {...props} />
      );
    case "ant-design":
      return (
        <SiAntdesign color="#0170FE" className="cc-language-icon" {...props} />
      );
    case "docker":
      return (
        <SiDocker color="#2496ED" className="cc-language-icon" {...props} />
      );
    case "expo":
      return <SiExpo color="#000020" className="cc-language-icon" {...props} />;
    case "express":
      return (
        <SiExpress color="#000000" className="cc-language-icon" {...props} />
      );
    case "firebase":
      return (
        <SiFirebase color="#FFCA28" className="cc-language-icon" {...props} />
      );
    case "git":
      return <SiGit color="#F05032" className="cc-language-icon" {...props} />;
    case "github":
      return (
        <SiGithub color="#181717" className="cc-language-icon" {...props} />
      );
    case "google-cloud":
      return (
        <SiGooglecloud
          color="#4285F4"
          className="cc-language-icon"
          {...props}
        />
      );
    case "ipfs":
      return <SiIpfs color="#65C2CB" className="cc-language-icon" {...props} />;
    case "jwt":
      return (
        <SiJsonwebtokens
          color="#000000"
          className="cc-language-icon"
          {...props}
        />
      );
    case "markdown":
      return (
        <SiMarkdown color="#000000" className="cc-language-icon" {...props} />
      );
    case "azure":
      return (
        <SiMicrosoftazure
          color="#0078D4"
          className="cc-language-icon"
          {...props}
        />
      );
    case "nginx":
      return (
        <SiNginx color="#009639" className="cc-language-icon" {...props} />
      );
    case "nodejs":
      return (
        <SiNodedotjs color="#339933" className="cc-language-icon" {...props} />
      );
    case "nodemon":
      return (
        <SiNodemon color="#76D04B" className="cc-language-icon" {...props} />
      );
    case "npm":
      return <SiNpm color="#CB3837" className="cc-language-icon" {...props} />;
    case "paypal":
      return (
        <SiPaypal color="#00457C" className="cc-language-icon" {...props} />
      );
    case "postman":
      return (
        <SiPostman color="#FF6C37" className="cc-language-icon" {...props} />
      );
    case "prettier":
      return (
        <SiPrettier color="#F7B93E" className="cc-language-icon" {...props} />
      );
    case "pug":
      return <SiPug color="#A86454" className="cc-language-icon" {...props} />;
    case "rabbitmq":
      return (
        <SiRabbitmq color="#FF6600" className="cc-language-icon" {...props} />
      );
    case "react-router":
      return (
        <SiReactrouter
          color="#CA4245"
          className="cc-language-icon"
          {...props}
        />
      );
    case "redis":
      return (
        <SiRedis color="#DC382D" className="cc-language-icon" {...props} />
      );
    case "redux":
      return (
        <SiRedux color="#764ABC" className="cc-language-icon" {...props} />
      );
    case "stripe":
      return (
        <SiStripe color="#008CDD" className="cc-language-icon" {...props} />
      );
    case "wordpress":
      return (
        <SiWordpress color="#21759B" className="cc-language-icon" {...props} />
      );
    case "zoho":
      return <SiZoho color="#C8202B" className="cc-language-icon" {...props} />;
    default:
      return <GrStatusUnknown {...props} />;
  }
};

export default LanguageIcon;
