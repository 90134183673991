import React, { Fragment, useState } from "react";
import { FiArrowUp } from "react-icons/fi";
import Lottie from "react-lottie-player";
import { flatten } from "lottie-colorify";
import instagram from "../lotties/instagram.json";
import linkedin from "../lotties/linkedin.json";
import mail from "../lotties/mail.json";
import "../styles/footer.css";

const Footer = () => {
  const [state, setState] = useState({
    instagram_hover: false,
    linkedin_hover: false,
    mail_hover: false,
  });

  const top = () =>
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

  return (
    <Fragment>
      <div id="cc-footer-top">
        <h3 onClick={top}>
          <FiArrowUp className="cc-top-arrow" />
          &nbsp;&nbsp;To the top&nbsp;&nbsp;
          <FiArrowUp className="cc-top-arrow" />
        </h3>
      </div>
      <div id="cc-footer-container">
        <div className="spacer" />
        <h2 id="cc-name-text">Carter J. Cobb</h2>
        <div className="spacer" />
        <div id="cc-contact-container">
          <a
            className="cc-center cc-contact-a"
            href="https://www.instagram.com/carter_cobb/"
            target="__blank"
            onMouseOver={() =>
              setState({
                instagram_hover: true,
                linkedin_hover: false,
                mail_hover: false,
              })
            }
            onMouseLeave={() =>
              setState({
                mail_hover: false,
                linkedin_hover: false,
                instagram_hover: false,
              })
            }
          >
            <Lottie
              loop={false}
              play={state.instagram_hover}
              goTo={state.instagram_hover ? undefined : 0}
              animationData={
                state.instagram_hover
                  ? flatten("#C13584", instagram)
                  : flatten("#ffffff", instagram)
              }
              rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
              style={{ height: 50, marginRight: 12, width: 50 }}
            />
            <h4 className="cc-contact-text">Instagram</h4>
          </a>
          <a
            className="cc-center cc-contact-a"
            href="http://www.linkedin.com/in/carterjcobb"
            target="__blank"
            onMouseOver={() =>
              setState({
                linkedin_hover: true,
                instagram_hover: false,
                mail_hover: false,
              })
            }
            onMouseLeave={() =>
              setState({
                mail_hover: false,
                linkedin_hover: false,
                instagram_hover: false,
              })
            }
          >
            <Lottie
              loop={state.linkedin_hover}
              play={state.linkedin_hover}
              animationData={
                state.linkedin_hover
                  ? flatten("#0072b1", linkedin)
                  : flatten("#ffffff", linkedin)
              }
              rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
              style={{ height: 50, marginRight: 12, width: 50 }}
              speed={0.75}
            />
            <h4 className="cc-contact-text">LinkedIn</h4>
          </a>
          <a
            className="cc-center cc-contact-a"
            href="mailto:ccobb@cartercobb.com"
            target="__blank"
            onMouseOver={() =>
              setState({
                mail_hover: true,
                linkedin_hover: false,
                instagram_hover: false,
              })
            }
            onMouseLeave={() =>
              setState({
                mail_hover: false,
                linkedin_hover: false,
                instagram_hover: false,
              })
            }
          >
            <Lottie
              loop={false}
              play={state.mail_hover}
              animationData={
                state.mail_hover
                  ? flatten("#FAA0A0", mail)
                  : flatten("#ffffff", mail)
              }
              rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
              style={{ height: 50, marginRight: 12, width: 50 }}
            />
            <h4 className="cc-contact-text">Email</h4>
          </a>
        </div>
        <div className="spacer" />
        <p id="cc-footer-copyright">
          &copy;2022 Carter J. Cobb | <a href="/privacy">Privacy Policy</a> |{" "}
          <a href="/terms">Terms of Use</a>
        </p>
      </div>
    </Fragment>
  );
};

export default Footer;
